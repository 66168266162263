import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/Layout"
// import Inactivity from "../../components/Inactivity"

/* Farms page, uses the Video > Rich Text > Stepped Navigation structure */

class Farms extends React.Component {
  componentDidMount() {
    // Inactivity()
  }
  render() {
    return(
      <Layout>
        <Helmet>
          <title>Meet MJN - Visit One Of Our Farms - Milk</title>
        </Helmet>
        <main role="main" className="content-main">

          <section className="hero-area -general -video">
            <div className="video-player-wrapper">
              <div className="youtube-player-wrapper">
                <iframe className="youtube-player" id="youtube-video" title="video" width="540" height="304" src="https://www.youtube.com/embed/DH0rhJrfC-4?controls=0&modestbranding=1&playsinline=1rel=0" allow="autoplay" frameBorder="0"></iframe>
              </div>
            </div>
          </section>

          <section className="rich-text -bg-white -with-stepped">
            <nav className="breadcrumb" role="navigation">
              <Link to="/event-experience" title="Event Experience"> 
                <i className="fas fa-arrow-left"></i>
                Event Experience
              </Link>
            </nav>
            <h2>Milk Quality &amp; Traceability</h2>

            <p>On this farm we never use antibiotic growth promoters or hormones. We have a full traceability system from the time the calf is born to its tagging within hours of birth to the provision of a passport, it ensures that at all times we have full traceability.</p>
          </section>

          <ol className="stepped-navigation">
            <li className="stepped-navigation__item"><Link to="/event-experience/grazing"></Link></li>
            <li className="stepped-navigation__item"><Link to="/event-experience/family"></Link></li>
            <li className="stepped-navigation__item -active"><span>Milk Quality</span></li>
          </ol>
        </main>
      </Layout>
    )
  }
}

export default Farms